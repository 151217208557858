var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        title: "新建团体",
        visible: _vm.open,
        width: "600px",
        destroyOnClose: "",
      },
      on: {
        ok: _vm.save,
        cancel: function ($event) {
          _vm.open = false
        },
      },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "dataForm",
            staticStyle: { "padding-bottom": "70px" },
            attrs: {
              layout: "horizontal",
              model: _vm.formData,
              rules: _vm.rules,
            },
            on: { mouseover: _vm.topCenterMouseover },
          },
          "a-form-model",
          _vm.layout,
          false
        ),
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "原文名", prop: "originName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入原文名" },
                model: {
                  value: _vm.formData.originName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "originName", $$v)
                  },
                  expression: "formData.originName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "phonetic-item",
              attrs: { label: "译文名（全名）", prop: "processedName" },
            },
            [
              _c(
                "span",
                [
                  _c("a-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入译文名" },
                    model: {
                      value: _vm.formData.processedName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "processedName", $$v)
                      },
                      expression: "formData.processedName",
                    },
                  }),
                  _vm.formData.phoneticTextList &&
                  _vm.formData.phoneticTextList.length
                    ? _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formData.phoneticTextList
                                .map((p) => p.value)
                                .join("")
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.departName.indexOf("日语部") > -1
                ? _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.inputLoading, size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.addPhonetic(
                            _vm.formData.processedName,
                            "processedName"
                          )
                        },
                      },
                    },
                    [_vm._v("获取注音")]
                  )
                : _vm._e(),
              _vm.departName.indexOf("韩语部") > -1
                ? _c(
                    "a-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.recommendText(
                            _vm.formData.originName,
                            "processedName"
                          )
                        },
                      },
                    },
                    [_vm._v("查看推荐用字")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.departName.indexOf("日语部") > -1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "注音情况", prop: "processedName" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.formData.phoneticStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "phoneticStatus", $$v)
                        },
                        expression: "formData.phoneticStatus",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [
                        _vm._v("未注音"),
                      ]),
                      _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("已注音"),
                      ]),
                      _c("a-radio", { attrs: { value: 4 } }, [
                        _vm._v("每次注音"),
                      ]),
                      _c("a-radio", { attrs: { value: 5 } }, [
                        _vm._v("不需要注音"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "初次登场章节", prop: "debut" } },
            [
              _c("a-input-number", {
                attrs: { min: 0, placeholder: "请输入初次登场章节" },
                model: {
                  value: _vm.formData.debut,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "debut", $$v)
                  },
                  expression: "formData.debut",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "说明", prop: "description" } },
            [
              _c("a-input", {
                attrs: { placeholder: "限制五十字以内" },
                model: {
                  value: _vm.formData.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("terms-phonetic-modal", {
        ref: "termsPhoneticModal",
        on: {
          mouseover: _vm.topCenterMouseover,
          savePhonetic: _vm.savePhonetic,
        },
      }),
      _c("terms-recommend-modal", {
        ref: "termsRecommendModal",
        attrs: { parent: _vm.parent },
        on: {
          mouseover: _vm.topCenterMouseover,
          saveRecommend: _vm.saveRecommend,
        },
      }),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c(
              "a-button",
              {
                key: "back",
                on: {
                  click: function ($event) {
                    _vm.open = false
                  },
                },
              },
              [_vm._v("\n        取消\n      ")]
            ),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }