<template>
  <a-modal :maskClosable="false" title="新建团体" :visible="open" @ok="save" @cancel="open = false" width="600px" destroyOnClose>
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <a-form-model
      @mouseover="topCenterMouseover"
      style="padding-bottom: 70px;"
      ref="dataForm"
      layout="horizontal"
      :model="formData"
      :rules="rules"
      v-bind="layout"
    >
      <a-form-model-item label="原文名" prop="originName">
        <a-input v-model="formData.originName" placeholder="请输入原文名"></a-input>
      </a-form-model-item>
      <!--       <a-form-model-item label="译文名（全名）" prop="processedName">
        <a-input v-model="formData.processedName" placeholder="请输入译文名"></a-input>
      </a-form-model-item> -->
      <a-form-model-item label="译文名（全名）" prop="processedName" class="phonetic-item">
        <span>
          <a-input v-model="formData.processedName" style="width:200px" placeholder="请输入译文名"></a-input>
          <span style="margin-left:5px;" v-if="formData.phoneticTextList && formData.phoneticTextList.length">
            {{ formData.phoneticTextList.map(p => p.value).join('') }}
          </span>
        </span>
        <a-button
          :loading="inputLoading"
          size="small"
          v-if="departName.indexOf('日语部') > -1"
          @click="addPhonetic(formData.processedName, 'processedName')"
          >获取注音</a-button
        >
        <a-button
          size="small"
          @click="recommendText(formData.originName, 'processedName')"
          v-if="departName.indexOf('韩语部') > -1"
          >查看推荐用字</a-button
        >
      </a-form-model-item>
      <a-form-model-item v-if="departName.indexOf('日语部') > -1" label="注音情况" prop="processedName">
        <a-radio-group v-model="formData.phoneticStatus">
          <a-radio :value="0">未注音</a-radio>
          <a-radio :value="1">已注音</a-radio>
          <!--               <a-radio :value="2">姓未注音</a-radio>
              <a-radio :value="3">名未注音</a-radio> -->
          <a-radio :value="4">每次注音</a-radio>
          <a-radio :value="5">不需要注音</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="初次登场章节" prop="debut">
        <a-input-number :min="0" v-model="formData.debut" placeholder="请输入初次登场章节"></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="说明" prop="description">
        <a-input v-model="formData.description" placeholder="限制五十字以内"></a-input>
      </a-form-model-item>
    </a-form-model>
    <terms-phonetic-modal @mouseover="topCenterMouseover" ref="termsPhoneticModal" @savePhonetic="savePhonetic" />
    <terms-recommend-modal
      @mouseover="topCenterMouseover"
      ref="termsRecommendModal"
      :parent="parent"
      @saveRecommend="saveRecommend"
    />
    <template slot="footer">
      <div @mouseover="topCenterMouseover">
        <a-button key="back" @click="open = false">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="save">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
import termsPhoneticModal from './terms-phonetic-modal'
import termsRecommendModal from './terms-recommend-modal'
export default {
  props: {
    productionId: {
      type: Number,
      default: () => null
    },
    departName: {
      type: String,
      default: ''
    },
    parent: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    termsPhoneticModal,
    termsRecommendModal
  },
  data() {
    return {
      open: false,
      inputLoading: false,
      formData: {
        subjectImgUrl: [],
        phoneticTextList: [],
        gxList: [
          {
            gx: '',
            name: '',
            honorific: '',
            call: ''
          }
        ],
        teamList: [
          {
            team: null,
            teamDescription: ''
          }
        ],
        hasTeam: 2,
        autoPhoneticTrigger: 0,
        phoneticStatus: 0,
        characters: [
          {
            name: '',
            description: ''
          }
        ]
      },
      rules: {
        termTypeName: [{ required: true, message: '请选择分类', trigger: 'change' }],
        originName: [{ required: true, message: '请输入原文名', trigger: 'blur' }],
        debut: [{ required: true, message: '请输入初次登场章节', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        identity: [{ required: true, message: '请输入身份（职业）', trigger: 'blur' }]
        /* description: [{ required: true, message: '请输入说明', trigger: 'blur' }] */
        /* characters: [{ required: true, validator: this.validateCharacters }] */
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      }
    }
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    savePhonetic(data) {
      if (data.type == 'processedFamilyName') {
        this.formData.processedFamilyName = data.list.map(item => item.key).join('')
        this.formData.phoneticFamilyNameTextList = data.list
      } else if (data.type == 'processedGivenName') {
        this.formData.processedGivenName = data.list.map(item => item.key).join('')
        this.formData.phoneticGivenNameTextList = data.list
      } else if (data.type == 'originNickname') {
        this.formData.originNickname = data.list.map(item => item.key).join('')
        this.formData.phoneticNickNameTextList = data.list
      } else if (data.type == 'processedName') {
        this.formData.processedName = data.list.map(item => item.key).join('')
        this.formData.phoneticTextList = data.list
      }
      this.$forceUpdate()
    },
    saveRecommend(data) {
      if (data.type == 'processedFamilyName') {
        this.formData.processedFamilyName = data.list.map(item => item.value).join('')
      } else if (data.type == 'processedGivenName') {
        this.formData.processedGivenName = data.list.map(item => item.value).join('')
      } else if (data.type == 'originNickname') {
        this.formData.originNickname = data.list.map(item => item.value).join('')
      } else if (data.type == 'processedName') {
        this.formData.processedName = data.list.map(item => item.value).join('')
      }
      this.$forceUpdate()
    },
    async recommendText(text, type) {
      if (!text) return this.$message.error('请输入原文')
      this.inputLoading = true
      const res = await getAction('/guide/list', {
        current: 1,
        fieId: 'guidName',
        size: -1,
        typeId: 4
      })
      this.inputLoading = false
      //陈李说的一个平台只有一条推荐用字数据
      if (res.code == 200) {
        let arr = res.data.records.filter(item => item.classId == 16)
        let recommendTextData = arr.find(item =>
          item.departments.some(d => this.parent.departmentIds.some(dId => dId == d.id))
        )
        if (recommendTextData) {
          this.$refs.termsRecommendModal.openModal(recommendTextData, text, type)
        } else {
          this.$refs.termsRecommendModal.openModal({ blacklist: [] }, text, type)
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    async addPhonetic(text, type) {
      if (!text) return this.$message.error('请输入姓、名、略称')
      if (type == 'processedFamilyName' && this.formData.phoneticFamilyNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticFamilyNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(
          data,
          text,
          type,
          true,
          this.formData.phoneticFamilyNameTextList[0].type
        )
      } else if (type == 'processedGivenName' && this.formData.phoneticGivenNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticGivenNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticGivenNameTextList[0].type)
      } else if (type == 'originNickname' && this.formData.phoneticNickNameTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticNickNameTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticNickNameTextList[0].type)
      } else if (type == 'processedName' && this.formData.phoneticTextList.map(p => p.value).join('')) {
        this.$message.error('检测到修改过译文名，请注意检查注音是否正确')
        let data = [{ word: [] }]
        this.formData.phoneticTextList.forEach(item => {
          data[0].word.push({
            surface: item.key,
            roman: '',
            furigana: item.value
          })
        })
        this.$refs.termsPhoneticModal.openModal(data, text, type, true, this.formData.phoneticTextList[0].type)
      } else {
        this.inputLoading = true
        const res = await getAction('/qualityInspection/questPhonetic', {
          sentence: text,
          type: 'single',
          grade: 1
        })
        if (res.code == 200) {
          this.$refs.termsPhoneticModal.openModal(res.data, text, type, false)
        } else {
          this.$message.error(res.msg)
        }
        this.inputLoading = false
      }
    },
    openModal() {
      this.open = true
      this.formData = {
        subjectImgUrl: [],
        phoneticTextList: [],
        autoPhoneticTrigger: 0,
        phoneticStatus: 0,
        gxList: [
          {
            gx: '',
            name: '',
            honorific: '',
            call: ''
          }
        ],
        teamList: [
          {
            team: null,
            teamDescription: ''
          }
        ],
        hasTeam: 2,
        characters: [
          {
            name: '',
            description: ''
          }
        ]
      }
    },
    save() {
      this.$refs['dataForm'].validate(async valid => {
        if (valid) {
          this.formData.teamList?.forEach(item => {
            item.anotherTermId = item.team
            item.typeDesc = 'left'
            item.fromOtherInfo = {
              call: item.teamDescription
            }
          })
          if (this.formData.phoneticTextList.map(p => p.value).join('')) {
            this.formData.phoneticStatus = 1
          } else {
            this.formData.phoneticStatus = 0
          }
          const res = await postAction('/term/edit', {
            term: {
              ...this.formData,
              productionId: this.productionId,
              termType: '团体',
              termTypeName: '团体',
              anotherTermId: this.formData.team
            },
            relations: {}
          })
          if (res.code == 200) {
            this.$message.success('新增成功')
            this.open = false
            this.$emit('afterSave')
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-wrap {
  z-index: 1050 !important;
}
.ant-form {
  padding-bottom: unset !important;
}
</style>
